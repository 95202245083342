<template>
  <MainLayout>
    <div v-if="!$store.state.loader && cilcData">
      <div class="yip-page-small">
        Your investment profile
      </div>
      <div class="my-2 mb-md-3 yip-page-title" id="yipTitle">
        {{ cilcData.title }}
      </div>

      <div class="row">
        <div class="col-12 col-md-4 col-lg-6 yip-page-subtitle" id="uipGoal">
          Goal: {{ cilcData.goal }}
        </div>

        <div class="col-12 d-none d-md-block d-lg-none" />
        <div class="col-6 d-none d-md-block d-lg-none" />

        <div class="col-12 col-md-6 mt-4 mt-lg-auto" id="yipAllocation">
          <span class="yip-page-body-1">Target allocation:</span>
          <div class="d-flex flex-column mt-3 yip-page-body-2">
            <div
              class="d-flex justify-content-between mb-2"
              v-for="(item, i) in Object.keys(cilcData.allocation)"
              :key="'alloc' + i"
            >
              <span>{{ item }}</span>
              <span>{{ cilcData.allocation[item] }}</span>
            </div>

            <div
              class="mt-3 d-flex justify-content-end"
              v-if="skip_passbase"
            >
              <router-link
                :to="{ name: 'LifeSituation', params: { id: $route.params.id } }"
                class="btn btn-primary px-4 button-padding"
              >
                I am ready, let's start!&nbsp;&nbsp; &rarr;
              </router-link>
            </div>
            <div
              class="mt-3 d-flex justify-content-end"
              v-else
            >
              <router-link
                :to="{ name: 'OnlineIdentification', params: { id: $route.params.id } }"
                class="btn btn-primary px-4 button-padding"
              >
                I am ready, let's start!&nbsp;&nbsp; &rarr;
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="row slider-chart-block mt-5 rounded-3 overflow-hidden">
        <div class="col-12 col-md-6 d-flex align-items-center">
          <div class="d-flex flex-column p-md-4 col-12 yip-page-body-3">
            <div>
              <div class="d-flex justify-content-between">
                <div>Starting investment</div>
                <div id="yipStInvestment">{{ chfFilter(money) }} CHF</div>
              </div>
              <div class="sw-range mt-3">
                <Slider
                  v-model="money"
                  id="yipStInvestmentSlider"
                  :tooltips="false"
                  :min="5000"
                  :max="500000"
                  @change="updateChart(); storeChartData()"
                  @update="updateChart"
                />
              </div>
            </div>

            <div class="my-45">
              <div class="d-flex justify-content-between">
                <div>Monthly deposit</div>
                <div id="yipMDeposit">{{ deposit }} CHF</div>
              </div>
              <div class="sw-range mt-3">
                <Slider
                  v-model="deposit"
                  id="yipMDepositSlider"
                  :tooltips="false"
                  :min="100"
                  :max="5000"
                  @change="updateChart(); storeChartData()"
                  @update="updateChart"
                />
              </div>
            </div>

            <div>
              <div class="d-flex justify-content-between">
                <div>Years</div>
                <div id="yipPeriod">{{ period }} {{ period === 1 ? 'year' : 'years' }}</div>
              </div>
              <div class="sw-range mt-3">
                <Slider
                  v-model="period"
                  id="yipPeriodSlider"
                  :tooltips="false"
                  :min="1"
                  :max="15"
                  @change="updateChart(); storeChartData()"
                  @update="updateChart"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 p-md-0 mt-5 mt-md-0 position-relative">
          <div id="chart" />
          <div class="chartYears" id="chartYears">
            <div
              v-for="p in period"
              :key="'year' + p"
              :id="'yipYear' + p"
            >
              {{ year + p }}
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div
          class="
            d-flex
            justify-content-between
            bordered-wrapper
            pb-3
            px-md-0
            yip-text
            flex-wrap
            col-12
            my-3
            align-items-baseline
            active
          "
        >
          <div class="col-12 col-md-8">
            <div>Expected return after {{ period }} {{ period === 1 ? 'year' : 'years' }}</div>
            <div class="my-3 my-md-auto yip-text_small" />
          </div>
          <div class="col-md-4 col-12 d-flex justify-content-md-end" id="yipRetNorm">
            {{ chfFilter(data[1][data[1].length - 1]) }} CHF
          </div>
        </div>

        <div
          class="
            d-flex
            justify-content-between
            bordered-wrapper
            pb-3
            px-md-0
            yip-text
            flex-wrap
            col-12
            my-3
            align-items-baseline
          "
        >
          <div class="col-12 col-md-8">
            <div>Expected max return after {{ period }} {{ period === 1 ? 'year' : 'years' }}</div>
            <div class="my-3 my-md-auto yip-text_small">
              There is 35% chance your investments will be below this number
            </div>
          </div>
          <div class="col-md-4 col-12 d-flex justify-content-md-end" id="yipRetMax">
            {{ chfFilter(data[2][data[2].length - 1]) }} CHF
          </div>
        </div>

        <div
          class="
            d-flex
            justify-content-between
            bordered-wrapper
            pb-3
            px-md-0
            yip-text
            flex-wrap
            col-12
            my-3
            align-items-baseline
          "
        >
          <div class="col-12 col-md-8">
            <div>Expected min return after {{ period }} {{ period === 1 ? 'year' : 'years' }}</div>
            <div class="my-3 my-md-auto yip-text_small">
              There is 76% chance your investments will be above this number
            </div>
          </div>
          <div class="col-md-4 col-12 d-flex justify-content-md-end" id="yipRetMin">
            {{ chfFilter(data[3][data[3].length - 1]) }} CHF
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="p-md-0 yip-page-extra-small">
          Investing implies that your capital is at risk. The value of your
          portfolio is influenced by market fluctuations and you may get back
          less than you invest. Past performance of market indices and/or ETFs
          is not an indicator for future performance. Notes on the Target
          Allocation: The Target Allocation will be in ETFs that invest in the
          particular type of asset listed. There may be variances of up to 20-%
          on either side of each target asset allocation.
        </div>

        <div class="sw-check col-12 mt-5 mt-md-3 p-md-0">
          <input
            type="checkbox"
            id="IAgree"
            checked="checked"
          >
          <label for="IAgree">
            I agree with Terms & Conditions and Privacy Policy
          </label>
        </div>
        <div class="col-12 col-md-auto mt-5 mb-4 mt-md-auto p-md-0">
          <router-link
            v-if="skip_passbase"
            :to="{ name: 'LifeSituation', params: { id: $route.params.id } }"
            class="btn btn-primary px-4 button-padding"
          >
            I am ready, let's start!&nbsp;&nbsp; &rarr;
          </router-link>
          <router-link
            v-else
            :to="{ name: 'OnlineIdentification', params: { id: $route.params.id } }"
            class="btn btn-primary px-4 button-padding"
          >
            I am ready, let's start!&nbsp;&nbsp; &rarr;
          </router-link>
        </div>
      </div>
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      class="shape"
    >
      <defs>
        <linearGradient
          id="header-shape-gradient1"
          x2="0.35"
          y2="1"
        >
          <stop
            offset="0%"
            style="stop-color: #5924eb; stop-opacity: 1"
          />
          <stop
            offset="100%"
            style="stop-color: #6b74fb; stop-opacity: 0.1"
          />
        </linearGradient>

        <linearGradient
          id="header-shape-gradient2"
          x2="0.35"
          y2="1"
        >
          <stop
            offset="0%"
            style="stop-color: #ff6363; stop-opacity: 1"
          />
          <stop
            offset="100%"
            style="stop-color: #6b74fb; stop-opacity: 0.1"
          />
        </linearGradient>
        <linearGradient
          id="header-shape-gradient3"
          x2="0.35"
          y2="1"
        >
          <stop
            offset="0%"
            style="stop-color: #3c7aff; stop-opacity: 1"
          />
          <stop
            offset="100%"
            style="stop-color: #6b74fb; stop-opacity: 0.1"
          />
        </linearGradient>
      </defs>
    </svg>
  </MainLayout>
</template>


<style lang="scss" >
.shape {
  height: 0;
  width: 0;
}

#header-shape-gradient {
  --color-stop: #6b74fb;
  --color-bot: rgba($color: #6b74fb, $alpha: 0);
}

.c3-region {
  fill: rgba($color: #ffffff, $alpha: 0.3);

  &:nth-child(2n) {
    fill: #e8ebf5;
  }
}

.c3-area-data1
.c3-area-data2,
.c3-area-data3 {
  fill: #6b74fb;
  opacity: 0.6;
}

.c3-tooltip {
  background: transparent;
  box-shadow: none;
  tr {
    border: none;
  }
  tr:first-child {
    display: none;
  }
  .name {
    display: none;
  }
  .value {
    color: #fff;
    border-radius: 10px;
    box-shadow: 7px 7px 12px -9px #777777;
    padding: 8px;
    border: none;
    margin-bottom: 5px;
    display: block;
  }
}
.c3-tooltip-name--data2 {
  .value {
    background: #ff6363;
  }
}
.c3-tooltip-name--data1 {
  .value {
    background: #5924eb;
  }
}
.c3-tooltip-name--data3 {
  .value {
    background: #3c7aff;
  }
}
</style>

<style lang="scss" scoped>
#chart {
  width: 100%;
  height: 100%;
}

.chartYears {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  padding-bottom: 20px;

  div {
    width: 100%;
    text-align: center;
    font-size: 9px;
    line-height: 125%;
    letter-spacing: 0.08px;
  }
}
</style>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import Slider from "@vueform/slider";
import { mapActions, mapGetters } from "vuex";

import "c3/src/scss/main.scss";

import c3 from "c3";

export default {
  components: {
    MainLayout,
    Slider,
  },
  name: "YourInvestmentProfile",
  data: () => {
    return {
      loader: false,
      timer: null,
      cilcData: null,
      money: 5000,
      deposit: 250,
      period: 1,
      data: {
        1: [0],
        2: [0],
        3: [0],
      },
      year: new Date().getFullYear(),
      chart: null,
      skip_passbase: process.env.VUE_APP_SKIP_PASSBASE == 'true'
    };
  },
  computed: {
    ...mapGetters(["gettedData", "onbForm"]),
  },
  methods: {
    ...mapActions(["turnOnLoader", "turnOffLoader", "fetchForm"]),
    getReturn(period, money, monthly, percent) {
      const increase = ((100 + percent) / 100) ** (1 / 365);
      const returnsInDays = [];
      [...Array(period * 365).keys()].reduce((item, index) => {
        let dayReturn = item * increase;
        if (index > 0 && index % 30 === 0) {
          dayReturn += monthly;
        }
        returnsInDays.push(dayReturn);
        return dayReturn;
      }, money);
      return returnsInDays.filter((value, index, data) => {
        if (index % 365 === 0 || index === data.length - 1) {
          return value;
        }
        return null;
      });
    },
    async check(init = false) {
      if (!init) {
        this.fetchForm();
      }
      if (
        !this.gettedData ||
        (this.gettedData && this.gettedData.length == 0) ||
        (this.gettedData &&
          this.gettedData.length > 0 &&
          this.gettedData.investmentProfile !== undefined)
      ) {
        this.turnOnLoader();
      } else {
        this.cilcData = this.gettedData.calculatedProfile;
        this.turnOffLoader();
        clearInterval(this.timer);
        this.renderChart();
      }
    },
    storeChartData(){
      this.$store.state.form.prefilledData.deposit = this.money
      this.$store.state.form.prefilledData.monthly = this.deposit
      this.$store.state.form.prefilledData.period = this.period
      this.$store.dispatch('storeForm')
    },
    chfFilter(value) {
      return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 0,
      }).format(value);
    },
    updateChart() {
      this.renderChart();
    },
    renderChart() {
      let vm = this;
      this.data[1] = this.getReturn(
        this.period,
        this.money,
        this.deposit,
        this.cilcData.return.expected
      );

      this.data[2] = this.getReturn(
        this.period,
        this.money,
        this.deposit,
        this.cilcData.return.upper
      );

      this.data[3] = this.getReturn(
        this.period,
        this.money,
        this.deposit,
        this.cilcData.return.lower
      );

      this.chart = c3.generate({
        bindto: "#chart",
        padding: {
          top: -5,
          right: -5,
          bottom: -10,
          left: -5,
        },
        data: {
          columns: [
            ["data1", ...this.data[1]],
            ["data2", ...this.data[2]],
            ["data3", ...this.data[3]],
          ],
          type: "area",
          colors: {
            data1: "URL(#header-shape-gradient1)",
            data2: "URL(#header-shape-gradient2)",
            data3: "URL(#header-shape-gradient3)",
          },
        },
        point: {
          show: false,
        },
        tooltip: {
          grouped: true,
          format: {
            value: function (x) {
              return "CHF " + vm.chfFilter(x);
            },
          },
        },
        axis: {
          y: {
            show: false,
          },
          x: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        regions: [
          { start: 0, end: 1, opacity: 1 },
          { start: 1, end: 2, opacity: 1 },
          { start: 2, end: 3, opacity: 1 },
          { start: 3, end: 4, opacity: 1 },
          { start: 4, end: 5, opacity: 1 },
          { start: 5, end: 6, opacity: 1 },
          { start: 6, end: 7, opacity: 1 },
          { start: 7, end: 8, opacity: 1 },
          { start: 8, end: 9, opacity: 1 },
          { start: 9, end: 10, opacity: 1 },
          { start: 10, end: 11, opacity: 1 },
          { start: 11, end: 12, opacity: 1 },
          { start: 12, end: 13, opacity: 1 },
          { start: 13, end: 14, opacity: 1 },
          { start: 14, end: 15, opacity: 1 },
          { start: 15, end: 16, opacity: 1 },
        ],
      });
    },
  },
  mounted() {
    this.check(true);
    let vm = this;
    this.timer = setInterval(() => {
      vm.check();
    }, 1000);
    this.money = this.$store.state.form.calculatedProfile.money || this.money
    this.deposit = this.$store.state.form.calculatedProfile.monthly || this.deposit
    this.period = this.$store.state.form.calculatedProfile.period || this.period
  },
};
</script>
